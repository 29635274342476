import Vue from 'vue'
import App from './App.vue'
import router from './router'

import 'normalize.css'
import './assets/css/style.css'
import './assets/css/responsive.css'
import './assets/css/scroll.css'


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap'
import 'jquery'
import './assets/css/owl.theme.default.css'
import './assets/css/owl.carousel.css'


// import './assets/js/SmoothScroll.js'
import './assets/js/jquery.isotope.js'
import './assets/js/owl.carousel.js'
import './assets/js/plugins'
import './assets/js/main.js'

var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)

// router.afterEach((to, from, next) => {
// 	window.scrollTo(0, 0);
// });

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')