import request from './config.js'

export const apiHeader = () => request.get('/header')
export const apiHome = () => request.get('/HomePageData')
export const apiAbout = () => request.get('/AboutData')
export const apiProfessional = () => request.get('/ProfessionalData')
export const apiService = () => request.get('/ServiceData')
export const apiContact = () => request.get('/ContactPageData')
export const apiReport = () => request.get('/ReportPageData')
export const apiResource = () => request.get('/ResourcePageData')
export const apiProductMenu = () => request.get('/ProductMenu')
export const apiProductData = data => request.get('/ProductPageData?'+ ( data.ptid?'&ptid=' + data.ptid:'') + (data.psid? '&psid=' + data.psid:'') )
export const apiInvestData = l1 => request.get('/InvestPageData?l1='+ l1 )
export const apiInvestMenu = () => request.get('/InvestMenu' )
export const apiPreview = () => request.get('/Preview' )
export const apiPublish = () => request.get('/Publish' )