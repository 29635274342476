<template>
  <div id="app">
    <div id="preview" v-if="preview=='Y'">
      <span>預覽模式</span>
      <a id="closepreview"   @click="closePreview()">
        <i class="fa fa-window-close"></i>
      </a>
    </div>
    <a id="gotop">
      <i class="fa fa-angle-up"></i>
    </a>
    <router-view/>
    <Footer/>
  </div>
</template>
<script>

import Footer from './components/Footer.vue'
import { apiPreview, apiPublish } from "@/api/pages.js";
export default {
  name: "App",
  components: {
    Footer,
  },
  data: {
    preview:null
  },
  created:function(){

    const queryString = window.location.search;
    console.log(queryString);
    const urlParams = new URLSearchParams(queryString);
    const preview = urlParams.get('preview');
    console.log( preview)
    if(preview)
    {
      this.preview = 'Y'
      $("#app").css("margin-top", "50px");
      apiPreview();
    }
  },
  mounted:function(){
    console.log( 'App.vue mounted')
    console.log( location.href)
    
    if(this.preview)
    {
      $("#app").css("margin-top", "50px");
    }
    $(function() {
      $('#gotop').on('click',function(){
        $('html,body').animate({ scrollTop: 0 }, 'slow');
        return false;
      });
      $(window).scroll(function() {
        if ( $(this).scrollTop() > 400){
            $('#gotop').fadeIn();
        } else {
            $('#gotop').fadeOut();
        }
      })
    })
  },
  methods:{
    async closePreview(){
      try
      {
        await apiPreview();
      }
      catch (e)
      {}
      location.href = location.href.replace("preview=Y","")
    },
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>